
import '../styles/subscription-new.scss'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useUserAuth } from '../hooks/useUserAuth'
import api from '../services/api'
import { toast } from 'react-toastify'
import { Header } from '../componentes/Header'
import { FormMain } from '../componentes/subscription/FormMain'
import PaymentType from '../types/payment'
import { FormPix } from '../componentes/subscription/FormPix'
import { FormCC } from '../componentes/subscription/FormCC'

export function SubscriptionNew() {
    const { user } = useUserAuth()
    const [payment, setPayment] = useState<PaymentType>()
    const [step, setStep] = useState('Main')

    async function handleMain(newPayment: PaymentType) {
        console.log(newPayment)

        if (newPayment.type === "PIX") {
            await api.post('pix', new URLSearchParams({
                id: newPayment.priceid
            }))
                .then((response) => {
                    toast.info(response?.data.msg)
                    setStep('PIX')
                    const updatedPayment = { ...newPayment }
                    updatedPayment.pix = response.data
                    setPayment(updatedPayment)
                })
                .catch((e) => {
                    if (e.response) {
                        toast.error(e.response?.data.error)
                    }
                    setStep('Main')

                })

        } else {
            const updatedPayment = { ...newPayment }
            updatedPayment.creditCard = {
                CardExpiration: "",
                CardHolder: "",
                CardNumber: "",
                CardSecurity: "",
                Zipcode: ""
            }
            setPayment(updatedPayment)
            setStep('CC')
        }

    }

    useEffect(() => {
        async function load() {
            if(user){
                setPayment({
                    priceid: "",
                    user: user,
                    type: "PIX"
                })
            }
        }
        load()
    }, [user])


    return (
        <div id="page-room">
            <Header />
            <main className='content-subscription-new'>

                <div className='title'>
                    <h1>Nova Assinatura</h1>
                </div>
                <div className={'compra-list'}>
                    {user && payment && step === 'Main' && (
                        <FormMain onChange={handleMain} payment={payment} />
                    )}
                    {user && payment && step === 'PIX' && (
                        <FormPix onChange={handleMain} payment={payment} />
                    )}
                    {user && payment && step === 'CC' && (
                        <FormCC onChange={handleMain} payment={payment} />
                    )}

                </div>

            </main>
        </div>
    )
}