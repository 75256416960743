import React, { useEffect, useState } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "../styles/carousel.scss"
import "../styles/video-list.scss"
import VideoType from "../types/video"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import api from "../services/api"
import { useUserAuth } from "../hooks/useUserAuth"
import locker from '../assets/img/locked.png'
import PostType from "../types/post"

const CarouselPost: React.FC = () => {
  const history = useNavigate()
  const [posts, setPosts] = useState<PostType[]>([])
  const [percent, setPercent] = useState(50)
  var user = useUserAuth()

  function go(id: string) {
    history('/post/' + id)
  }



  useEffect(() => {
    async function loadVideos() {
      const response = await api.post('post', new URLSearchParams({
        find: ''
      }))
      setPosts(response.data)
    }
    loadVideos()
  }, [])

  return (
    <div className="carousel-container">
      <h2>Leia agora</h2>
      <Carousel centerSlidePercentage={percent} centerMode showStatus={false} showIndicators={false} showThumbs={false} >
        {posts.map((post) => {
          return (
            <div className="lista-videos">
              <article className="conto" key={post.id} onClick={() => { go(post.id) }}>
                <div className='thumb-conto' style={{ backgroundImage: `url(${post.thumb})` }}>
                  <h6 className="thumb-h6">{post.title}</h6>
                </div>
              </article>
            </div>
          )
        }
        )}
      </Carousel>
    </div>
  )
}

export default CarouselPost
