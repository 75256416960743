import React, { useState, useEffect } from 'react'
import api from '../services/api'

interface Option {
    id: string;
    name: string;
}

interface SelectCategoryProps {
    onSelectChange?: (value: string) => void
}

function SelectCategory(props: SelectCategoryProps) {
    const [data, setData] = useState<Option[]>([]) // Estado para armazenar os dados da API
    const [search, setSearch] = useState('0') // Estado para armazenar a seleção do usuário

    function setHandleSelectChange(value: string) {
        setSearch(value)
        if (props.onSelectChange) {
            props.onSelectChange(value);
        }
    }

    useEffect(() => {
        // Função para buscar os dados da API
        async function fetchData() {
            try {
                const response = await api.get('category/list')
                setData(response.data)
            } catch (error) {
                console.error('Erro ao buscar os dados da API:', error)
            }
        }

        fetchData(); // Chama a função de busca quando o componente é montado
    }, [])

    return (
        <select name='category' value={search} onChange={event => setHandleSelectChange(event.target.value)}>
            <option key='0' value='0'>
                Todas as categorias
            </option>
            {data.map(item => (
                <option key={item.id} value={item.id}>
                    {item.name}
                </option>
            ))}
        </select>
    );
}

export default SelectCategory
