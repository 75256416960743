import axios from 'axios'


const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL
});

api.interceptors.request.use(async config => {
  const token = localStorage.getItem("token")

  let configWithHeaders = {};

  if (token) {
    configWithHeaders = {
      headers: {
        Authorization: token,
      },
    }
  }

  return { ...config, ...configWithHeaders };
})

api.interceptors.response.use(
  response => {
    return response;
  },

  error => {
    localStorage.clear()
    return Promise.reject(error)
  }
)

export default api