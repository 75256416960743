
import '../styles/favorites.scss'
import { useNavigate } from 'react-router-dom'
import { Header } from '../componentes/Header'
import { useEffect, useState } from "react"
import api from '../services/api'
import VideoType from '../types/video'



export function Favorites() {
    const history = useNavigate()
    const [videos, setVideos] = useState<VideoType[]>([])

    function go(id:string){
        history('/video/'+id)
      }

    useEffect(() => {
        async function loadVideos() {
            const response = await api.get("video")
            setVideos(response.data);
        }
        loadVideos();
    }, []);

    return (
        <div id="page-room">
            <Header />
            <main className='content'>
                <div className="container-favorites">
                    <div className='title-favorites'>
                        <h1>Meus Favoritos</h1>
                    </div>
                    <div className="lista-videos">
                        {videos.map((video) => {
                            return (
                                <article className="video" key={video.id} onClick={() => { go(video.id) }}>
                                    <div className='thumb' style={{ backgroundImage: `url(${video.thumb})` }}>
                                        <h6 className="thumb-h6">{video.title}</h6>
                                    </div>

                                </article>
                            );
                        })}
                    </div>
                </div>
            </main>
        </div>
    )
}