
import '../styles/post.scss'
import { useNavigate } from 'react-router-dom'
import { Header } from '../componentes/Header'
import { useEffect, useState } from "react"
import api from '../services/api'
import PostType from '../types/post'



export function Post() {
    const history = useNavigate()
    const [posts, setPosts] = useState<PostType[]>([])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState('')

    function go(id: string) {
        history('/post/' + id)
    }

    async function searching(find: string) {
        setSearch(find)
        const response = await api.post('post', new URLSearchParams({
            find: find,
        }))
        setPosts(response.data)
    }

    useEffect(() => {
        async function loadVideos() {
            const response = await api.post('post', new URLSearchParams({
                find: ''
            }))
            setPosts(response.data)
        }
        loadVideos()
        setLoading(false)
    }, [])

    return (
        <div id="page-room">
            <Header />
            <main className='content'>
                <div className='title-contos'>
                    <h1>Contos</h1>
                    <input
                        type='text'
                        placeholder='Buscar'
                        onChange={event => searching(event.target.value)}
                        value={search}
                    />
                </div>
                <div className={`${loading ? 'container-contos' : 'hide'}`}>
                    <div className='searching'>
                        <h1>Carregando...</h1>
                    </div>
                </div>
                <div className={`${loading ? 'hide' : 'container-contos'}`}>
                    <div className="lista-contos">
                        {posts.map((post) => {
                            return (
                                <article className="conto" key={post.id} onClick={() => { go(post.id) }}>
                                    <div className='thumb-conto' style={{ backgroundImage: `url(${post.thumb})` }}>
                                        <h6 className="thumb-h6">{post.title}</h6>
                                    </div>
                                </article>
                            );
                        })}
                    </div>
                </div>
            </main>
        </div>
    )
}