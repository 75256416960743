
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import api from '../services/api'
import ReactPlayer from 'react-player'
import { Header } from '../componentes/Header'
import VideoType from '../types/video'
import '../styles/video.scss'
import { toast } from "react-toastify"


export function Video() {
    const { id } = useParams()
    const history = useNavigate()
    const [video, setVideo] = useState<VideoType>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        async function loadVideos() {
            const response = await api.get(`google/${id}`)
            if (response.data.msg) {
                toast.error(response.data.msg)
                history("/")
                return
            }
            setVideo(response.data)
            setLoading(false)
        }
        loadVideos()
    }, [history, id]);

    if (loading) {
        return (
            <div id="page-room">
                <Header />
                <main className='content'>
                    <div className="video-info">
                        <div className="video-title">
                            <h1>Carregando seu video...</h1>
                        </div>
                    </div>
                </main>
            </div>
        )
    }

    return (
        <div id="page-room">
            <Header />
            <main className='content'>
                <div className="video-info">
                    <div className="video-title">
                        <h1>{video?.title}</h1>
                    </div>
                    <div className="video-player">
                        <div className="video-player-wrapper">
                            <ReactPlayer url={video?.url} controls={true} width="100%" max-height="80dvh" />
                        </div>
                    </div>
                    <div className="video-desc">
                        <h3>{video?.description}</h3>
                    </div>

                </div>
            </main>
        </div>
    )

}