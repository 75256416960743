
import logoIMG from '../assets/img/logo.png'
import '../styles/login.scss'
import { Button } from '../componentes/Button'
import { Navigate, useNavigate} from 'react-router-dom'
import { FormEvent, useState } from 'react'
import  api  from '../services/api'
import { useIsAuth } from '../hooks/useAuth'
import { toast } from 'react-toastify'



export function Login(){

    
    
    const history = useNavigate()
    const [login, setLogin] = useState('')
    const [senha, setSenha] = useState('')
    const [soujurado, setSoujurado] = useState('hiddensoujurado')
    const [exibeLogin, setExibeLogin] = useState('showlogin')

    async function handleLogin(event:FormEvent){
        event.preventDefault()
        
        await api.post('login', new URLSearchParams({
            email: login,
            password: senha
        }))
        .then((response) => {
            localStorage.setItem("token",response.data.Authorization)
            history('/')
            toast.success('Login Efetuado com sucesso!',{autoClose:2000})
        })
        .catch((e) =>{
            if (e.response) {
                // Is this the correct way?
                toast.error(e.response?.data.error)
                if(e.response?.data.error==="Login ou Senha inválido"){
                    history('/cadastro', { state: { email:login, password:senha } })
                    toast.error('Faça seu cadastro para acessar')
                }
            }
            
        })
    }

    async function showLogin(event:FormEvent){
        event.preventDefault()
        setSoujurado('hiddensoujurado')
        setExibeLogin('showlogin')
        
    }

    async function handleCompra(event:FormEvent){
        event.preventDefault()
        history('/cadastro')
    }

    if(useIsAuth()){
        return <Navigate to="/" state={{ from: '/login' }} replace />;
    }

    return(
        <div id="page-auth">
            <aside>
                <strong>Proibidão Ana Otani</strong>
                <p>Faça Login para se deslumbrar com o melhor conteúdo +18</p>
            </aside>
            <main>
                <div className="main-content">
                    <img src={logoIMG} alt="Logo" />
                    <p>Se cadastre e compre conteúdo a partir de R$4,99 e concorra a uma viagem para a Mansão das Influenciadoras</p>
                    <button onClick={handleCompra} className="create-room-google">
                        Quero me Cadastrar 
                    </button>
                    <div className={exibeLogin}>
                        <div className="separator">Já tem Login?</div>
                        <form onSubmit={handleLogin}>
                            <input 
                                type="text"
                                placeholder='Login'
                                onChange={event => setLogin(event.target.value)}
                                value={login}
                            />
                            <input 
                                type="password"
                                placeholder='Senha'
                                onChange={event => setSenha(event.target.value)}
                                value={senha}
                            />
                            <Button>Acessar Conteúdo</Button>
                        </form>
                    </div>
                    <div className={soujurado}>
                        <Button onClick={showLogin}>Já sou jurado</Button>
                    </div>
                </div>
            </main>
        </div>
    )
}

