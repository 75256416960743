import './componentes/Button'
import './styles/global.scss'
import 'react-toastify/dist/ReactToastify.css'

import { ToastContainer } from 'react-toastify'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { VideoList } from './pages/VideoList'
import { Login } from './pages/Login'
import { RequireAuth } from './context/RequireAuth'
import { Page404 } from './pages/Page404'
import { Cadastro } from './pages/Cadastro'
import { Subscription } from './pages/Subscription'
import { Video } from './pages/Video'
import { Favorites } from './pages/Favorites'
import { SubscriptionNew } from './pages/SubscriptionNew'
import { Post } from './pages/Post'
import { PostDetail } from './pages/PostDetail'
import { PhotoDetail } from './pages/PhotoDetail'
import { Photo } from './pages/Photo'
import { PhotoNew } from './pages/PhotoNew'
import { Lotto } from './pages/Lotto'
import { Home } from './pages/Home'
import { Affiliate } from './pages/Affiliate'



function App() {

  return (
    <BrowserRouter>
      <div>
        <ToastContainer />
        <Routes>
          <Route path="*" element={<Page404 />} />
          <Route path="/login" element={<Login />} />
          <Route path="/cadastro" element={<Cadastro />} />
          <Route path="/cadastro/:id" element={<Cadastro />} />
          
          <Route path="/" element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
          />
          <Route path="/subscription" element={
            <RequireAuth>
              <Subscription />
            </RequireAuth>
          }
          />
          <Route path="/favorites" element={
            <RequireAuth>
              <Favorites />
            </RequireAuth>
          }
          />
          <Route path="/post" element={
            <RequireAuth>
              <Post />
            </RequireAuth>
          }
          />
          <Route path="/post/:id" element={
            <RequireAuth>
              <PostDetail />
            </RequireAuth>
          }
          />
          <Route path="/photo/:id" element={
            <RequireAuth>
              <PhotoDetail />
            </RequireAuth>
          }
          />
          <Route path="/photo/new" element={
            <RequireAuth>
              <PhotoNew />
            </RequireAuth>
          }
          />
          <Route path="/photo" element={
            <RequireAuth>
              <Photo />
            </RequireAuth>
          }
          />
          <Route path="/lotto" element={
            <RequireAuth>
              <Lotto />
            </RequireAuth>
          }
          />
          <Route path="/video/:id" element={
            <RequireAuth>
              <Video />
            </RequireAuth>
          }
          />
          <Route path="/video" element={
            <RequireAuth>
              <VideoList />
            </RequireAuth>
          }
          />
          <Route path="/subscription/new" element={
            <RequireAuth>
              <SubscriptionNew />
            </RequireAuth>
          }
          />
          <Route path="/affiliate" element={
            <RequireAuth>
              <Affiliate />
            </RequireAuth>
          }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
