import { useEffect, useState } from 'react'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'
import { Button } from '../Button'
import copy from "copy-to-clipboard"
import PaymentType from '../../types/payment'
import { toast } from 'react-toastify'

interface FormPixProps {
    onChange: (newPayment: PaymentType) => void
    payment: PaymentType
}

export function FormPix(props: FormPixProps) {

    const [paymentState, setPaymentState] = useState<PaymentType>(props.payment)
    const history = useNavigate()

    async function handleCopiaLink() {
        if (paymentState.pix) {
            copy(paymentState.pix.qr_code)
            toast.success('Código copiado com sucesso!')
        } else {
            toast.error('Não foi possível copiar o código!')
        }
    }

    async function handleValidaPix() {
        await api.post('/pix/validate', new URLSearchParams({
            id_payment: props.payment.pix?.id ? props.payment.pix?.id : '0'
        }))
            .then((response) => {
                if (response?.data.status) {
                    toast.success(response?.data.msg)
                    history('/')
                }else{
                    toast.error(response?.data.msg)
                }
            })
            .catch((e) => {
                if (e.response) {
                    // Is this the correct way?
                    toast.error(e.response?.data.error)
                }

            })

    }

    useEffect(() => {
        async function load() {
            setPaymentState(props.payment)
        }
        load()
    }, [props.payment])


    return (
        <div className='pix-detail'>
            <h3>Leia o QRCode ou copie o link de pagamento</h3>
            <img src={paymentState.pix?.qr_code_url} alt='QR Code do PIX'/>
            <input
                readOnly
                type='text'
                placeholder='Chave PIX'
                value={paymentState.pix?.qr_code}
            />
            <Button onClick={handleCopiaLink}>Copiar Código PIX</Button>
            <div className="separator">Já efetuei o Pagamento</div>
            <Button onClick={handleValidaPix}>Liberar compra</Button>
        </div>
    )

}