import '../styles/home.scss'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../componentes/Button'
import { Header } from '../componentes/Header'
import CarouselVideo from '../componentes/CarouselVideo'
import CarouselPost from '../componentes/CarouselPost'
import { ShareBox } from '../componentes/ShareBox'


export function Home() {
    const history = useNavigate()

    return (
        <div id="page-room">
            <Header />
            <main className='home'>
                <div className='title'>
                    <h1>Home</h1>
                </div>
                <div className='container'>
                    <div className='section'>
                        <div className='col-50'>
                            <img className="logo" src='https://segredosdeana.com.br/pages/wp-content/uploads/2023/10/51814a3670f17d2e371d-jpeg.png' alt="Logo" />
                            <div>
                                <h2>Compre Fotos</h2>
                                <h2>a partir de R$4,99</h2>
                                <h4>Cada foto comprada ganhe um número para concorrer a uma Viagem para Mansão das Influenciadoras</h4>
                            </div>

                            <Button onClick={() => { history('/photo/new') }}>Comprar</Button>
                        </div>
                        <div className='col-50'>
                            <img className="logo" src='https://segredosdeana.com.br/pages/wp-content/uploads/2023/10/72d33dc9f7bc072dac3e-jpeg.png' alt="Logo" />
                            <div>
                                <h2>Assine Conteúdo</h2>
                                <h2>a partir de R$69,99</h2>
                                <h4>Assinando o conteúdo você ganha 20 números para concorrer a uma Viagem para Mansão das Influenciadoras</h4>
                            </div>
                            <Button onClick={() => { history('/subscription/new') }}>Assinar</Button>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <CarouselVideo />
                </div>
                <div className='container'>
                    <CarouselPost />
                </div>
                <div className='container'>
                    <ShareBox />
                </div>
                <div className='footer'>
                    <ul>
                        <li><Link to="/" >Termos de Uso</Link></li>
                        <li><Link to="/" >Política de Privacidade</Link></li>
                        <li><Link to="/" >Regulamento Concurso</Link></li>
                        <li><Link to="/" >Atendimento</Link></li>
                    </ul>
                </div>
            </main>
        </div>
    )

}