import React, { useEffect, useState } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "../styles/carousel.scss"
import "../styles/video-list.scss"
import VideoType from "../types/video"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import api from "../services/api"
import { useUserAuth } from "../hooks/useUserAuth"
import locker from '../assets/img/locked.png'

const CarouselVideo: React.FC = () => {
  const history = useNavigate()
  const [videos, setVideos] = useState<VideoType[]>([])
  const [percent, setPercent] = useState(50)
  var user = useUserAuth()

  async function go(id: string, type: string) {
    const response = await api.get(`subscription/active`)
    if (!response) {
      toast.error("Faça Login novamente")
    } else {
      if (!response.data.isActive && type !== "G") {
        toast.error("Assine para assistir")
        history('/subscription/new')
      } else {
        history('/video/' + id)
      }
    }

  }

  useEffect(() => {
    async function loadVideos() {
      if (window.innerWidth < 560) {
        setPercent(100)
      }
      const response = await api.post('video', new URLSearchParams({
        find: '',
        category: ''
      }))
      setVideos(response.data)
    }
    loadVideos()
  }, [])
  return (
    <div className="carousel-container">
      <h2>Assista agora</h2>
      <Carousel centerSlidePercentage={percent} centerMode showStatus={false} showIndicators={false} showThumbs={false} >
        {videos.map((video) => {
          return (
            <div className="lista-videos">
              <article className='video' key={video.id} onClick={() => { go(video.id, video.type) }}>
                <div className='thumb' style={{ backgroundImage: `url(${video.thumb})` }}>
                  <h6 className='thumb-h6'>{video.title}</h6>
                  {video.type === "G" &&
                    <div className="tag"><div className="freeTag">Grátis</div></div>
                  }
                  {video.type !== "G" && (!user.user?.subscription) &&
                    <div className="locker"><div className="freeLocker">
                      <h3>Conteúdo Exclusivo Assinantes</h3>
                      <img className="logo" src={locker} alt="Locker" />
                    </div></div>
                  }
                </div>
              </article>
            </div>
          )
        }
        )}
      </Carousel>
    </div>
  )
}

export default CarouselVideo
