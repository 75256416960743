import { useEffect, useState } from "react"
import PostType from "../types/post"
import { toast } from "react-toastify"
import { Header } from "../componentes/Header"
import { useNavigate, useParams } from "react-router-dom"
import api from "../services/api"
import '../styles/post-detail.scss'

export function PostDetail() {
    const history = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [post, setPost] = useState<PostType>()

    useEffect(() => {

        async function loadposts() {
            const response = await api.get(`post/${id}`)
            if (response.data.msg) {
                toast.error(response.data.msg)
                history("/")
                return
            }
            setPost(response.data)
            setLoading(false)
        }
        loadposts()
    }, [history, id]);

    if (loading) {
        return (
            <div id="page-room">
                <Header />
                <main className='content'>
                    <div className="post-info">
                        <div className="post-title">
                            <h1>Carregando seu post...</h1>
                        </div>
                    </div>
                </main>
            </div>
        )
    }

    return (
        <div id="page-room">
            <Header />
            <main className='content'>
                <div className="post-info">
                    <article className="conto-detail">
                        <div className='thumb-conto-detail' style={{ backgroundImage: `url(${post?.thumb})` }}>
                        </div>
                    </article>
                    <div className="post-title">
                        <h1>{post?.title}</h1>
                    </div>
                    <div className="post-desc">
                        <h3>{post?.text}</h3>
                    </div>

                </div>
            </main>
        </div>
    )
}