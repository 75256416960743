
import logoImg from '../assets/img/logo.png'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import '../styles/cadastro.scss'
import '../styles/header.scss'
import { Button } from '../componentes/Button'
import { FormEvent, useState } from 'react'
import api from '../services/api'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'



export function Cadastro() {
    const location = useLocation()
    const {email = '', password = '' } = location.state || {}
    console.log(location.state)
    const [nome, setNome] = useState('')
    const [login, setLogin] = useState(email)
    const [senha, setSenha] = useState(password)
    const [CPF, setCPF] = useState('')
    const [celular, setCelular] = useState('')
    const { id } = useParams()

    const history = useNavigate()

    async function handleCompra(event: FormEvent) {
        event.preventDefault()
        await api.post('create', new URLSearchParams({
            name: nome,
            email: login,
            password: senha,
            document: CPF,
            phone: celular,
            referal: id ? id : ''
        }))
            .then((response) => {
                toast.info(response?.data.msg)
                localStorage.setItem("token", response.data.token)
                history('/')
            })
            .catch((e) => {
                if (e.response) {
                    // Is this the correct way?
                    toast.error(e.response?.data.error)
                }
            })
    }

    function home() {
        history('/')
    }

    return (
        <div id="page-compra">
            <header>
                <div className="content-header">
                    <img src={logoImg} alt="Logo" onClick={home} />
                </div>
            </header>
            <main className='content'>
                <div className='room-title'>
                    <h1>Cadastro</h1>
                    <h3>Faça seu cadastro para acessar a plataforma</h3>
                </div>
                <div className='compra-list'>
                    <form onSubmit={handleCompra}>
                        <input
                            type="text"
                            placeholder='Nome'
                            onChange={event => setNome(event.target.value)}
                            value={nome}
                        />
                        <input
                            type="text"
                            placeholder='E-mail'
                            onChange={event => setLogin(event.target.value)}
                            value={login}
                        />
                        <input
                            type="password"
                            placeholder='Senha'
                            onChange={event => setSenha(event.target.value)}
                            value={senha}
                        />
                        <InputMask
                            name="cpf"
                            mask="999.999.999-99"
                            value={CPF}
                            onChange={event => setCPF(event.target.value)}
                            placeholder='CPF'
                        />
                        <InputMask
                            name="celular"
                            mask="(99)99999-9999"
                            value={celular}
                            onChange={event => setCelular(event.target.value)}
                            placeholder='Celular'
                            alwaysShowMask={false}
                        />
                        <Button>Cadastrar</Button>
                    </form>

                </div>
            </main>
        </div>
    )

}