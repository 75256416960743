
import '../styles/subscription.scss'
import { Button } from '../componentes/Button'
import { FormEvent, useEffect, useState } from 'react'
import api from '../services/api'
import { Header } from '../componentes/Header'
import SubscriptionType from '../types/subscription'
import { ModalSubscription } from '../componentes/ModalSubscription'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import PaymentType from '../types/payment'



export function Subscription() {
    const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [payment, setPayment] = useState<PaymentType>()
    const history = useNavigate()

    function closeModal() {
        setModalIsOpen(false)
    }

    async function openModal(id: string) {
        setModalIsOpen(true)
        await api.get('pix/' + id)
            .then((response) => {
                let updatedPayment = response.data
                setPayment(updatedPayment)
            })
            .catch((e) => {
                if (e.response) {
                    toast.error(e.response?.data.error)
                }
            })

    }

    function newSubscription() {
        history('/subscription/new')

    }


    useEffect(() => {
        async function loadSubscriptionVideos() {
            const response = await api.get("subscription/list");
            setSubscriptions(response.data);
        }
        loadSubscriptionVideos()
    }, [])

    return (
        <div id="page-room">
            <ModalSubscription isOpen={modalIsOpen} closeModal={closeModal} payment={payment} />
            <Header />
            <main className='content'>
                <div className='subscription-title'>
                    <h1>Assinaturas</h1>
                    <Button onClick={newSubscription}>Nova</Button>
                </div>
                <div className='subscription-list'>
                    <div className='list-flex'>
                        {subscriptions.length > 0 ? (
                            subscriptions.map((subscription) => {
                                return (
                                    <div key={subscription.id} className='subscription-table'>
                                        <div className='subscription-item'>
                                            <div className='flex-col'>
                                                <label className='label'>Tipo</label>
                                                <label className='label-valor'>{subscription.type}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Data Início</label>
                                                <label className='label-valor'>{subscription.date_start}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Data Final</label>
                                                <label className='label-valor'>{subscription.date_finish}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Código Pagamento</label>
                                                <label className='label-valor'>{subscription.external_id}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Status</label>
                                                <label className='label-valor'>{subscription.status}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Valor</label>
                                                <label className='label-valor'>{parseFloat(subscription.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</label>
                                            </div>
                                        </div>
                                        <div className='subscription-botton'>
                                            {subscription.status === "PAID" ? (
                                                <Button onClick={() => { history('/lotto') }}>Sorteio</Button>
                                            ) : (
                                                <Button onClick={() => { openModal(subscription.external_id) }}>Pagar</Button>
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className='no-data'>
                                <p>Nenhuma Assinatura foi encontrada</p>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </div>
    )
}