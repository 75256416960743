import '../styles/sharebox.scss'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import { useUserAuth } from '../hooks/useUserAuth'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'


export function ShareBox() {
    const history = useNavigate()
    const { user } = useUserAuth()
    const [link, setLink] = useState('Carregando...')
    const [count, setCount] = useState('..')

    async function handleChange(event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>){
        setLink(event.target.value)
    }

    async function handleWhatsapp(){
        window.open('https://api.whatsapp.com/send?text='+encodeURIComponent(link), '_blank');
    }

    async function handleFacebook(){
        window.open('https://www.facebook.com/sharer.php?u='+encodeURIComponent(link), '_blank');
    }

    async function handleLinkedin(){
        window.open('https://www.linkedin.com/sharing/share-offsite/?url='+encodeURIComponent(link), '_blank');
    }

    async function handleCopy(){
        copy(link)
        toast.success('Link copiado com sucesso')
    }

    useEffect(() => {
        if(user){
            setLink(`${process.env.REACT_APP_PUBLIC_URL}/cadastro/${user.code}`)
            setCount(user.indications)
        }
    }, [user])

    return (
        <div className='box-list'>
            <div className='title-performance-redes'><h1>Convide seus amigos e ganhe dinheiro</h1></div>
            <div className='item-performance-redes'>
                <div className='number-redes'>
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={handleFacebook} className='icon-facebook' width="44px" height="44px" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"></path></svg>
                </div>
                <div className='number-redes'>
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={handleWhatsapp} className='icon-whatsapp' width="44px" height="44px" viewBox="0 0 24 24"><path d="M12.036 5.339c-3.635 0-6.591 2.956-6.593 6.589-.001 1.483.434 2.594 1.164 3.756l-.666 2.432 2.494-.654c1.117.663 2.184 1.061 3.595 1.061 3.632 0 6.591-2.956 6.592-6.59.003-3.641-2.942-6.593-6.586-6.594zm3.876 9.423c-.165.463-.957.885-1.337.942-.341.051-.773.072-1.248-.078-.288-.091-.657-.213-1.129-.417-1.987-.858-3.285-2.859-3.384-2.991-.099-.132-.809-1.074-.809-2.049 0-.975.512-1.454.693-1.653.182-.2.396-.25.528-.25l.38.007c.122.006.285-.046.446.34.165.397.561 1.372.611 1.471.049.099.083.215.016.347-.066.132-.099.215-.198.33l-.297.347c-.099.099-.202.206-.087.404.116.198.513.847 1.102 1.372.757.675 1.395.884 1.593.983.198.099.314.083.429-.05.116-.132.495-.578.627-.777s.264-.165.446-.099 1.156.545 1.354.645c.198.099.33.149.38.231.049.085.049.482-.116.945zm3.088-14.762h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-6.967 19.862c-1.327 0-2.634-.333-3.792-.965l-4.203 1.103 1.125-4.108c-.694-1.202-1.059-2.566-1.058-3.964.002-4.372 3.558-7.928 7.928-7.928 2.121.001 4.112.827 5.609 2.325s2.321 3.491 2.32 5.609c-.002 4.372-3.559 7.928-7.929 7.928z"></path></svg>

                </div>
                <div className='number-redes'>
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={handleLinkedin} className='icon-linkedin' width="44px" height="44px" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"></path></svg>

                </div>
            </div>
            <div className='item-performance-redes' onClick={handleCopy}>
                <input
                    name="name"
                    type="text"
                    placeholder='Nome'
                    onChange={handleChange}
                    value={link}
                    readOnly
                />
                <div className='redes-copy'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z"></path></svg></div>
            </div>
            <div className='item-performance-redes'>
                <div className='number-redes'>
                    <span>{count}</span>
                    <label>Indicações</label>
                    <Link to='affiliate'>Ver meus ganhos</Link>
                </div>
            </div>
        </div>
    )
}