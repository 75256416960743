import '../styles/affiliate.scss'
import { useNavigate } from "react-router-dom"
import { Header } from "../componentes/Header"
import { useEffect, useState } from "react"
import api from '../services/api'
import UserType from '../types/user'
import { Button } from '../componentes/Button'
import { toast } from 'react-toastify'

type AffiliateType = {
    type: string
    name: string
    date: string
    value: string
    commission: string
    idkey: string
}

type BalanceListType = {
    type: string
    value: string
}

type BalanceType = {
    balance: string
}

export function Affiliate() {
    const history = useNavigate()
    const [loading, setLoading] = useState(true)
    const [affiliate, setAffiliate] = useState<AffiliateType[]>([])
    const [listBalance, setListBalance] = useState<BalanceListType[]>([])
    const [balance, setBalance] = useState<BalanceType>()

    async function createPayout() {
        const response = await api.get('getBalance')
        setBalance(response.data)
        if (balance?.balance) {
            if (parseFloat(balance?.balance) < 50.0) {
                toast.error('Você precisa atingir no mínimo R$50,00 para solicitar o reembolso! Atualmente você tem: '+parseFloat(balance.balance).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })+' em saldo')
            }else{
                api.post('createCashout')
                .then((response) => {
                    toast.info(response?.data.msg)
                    history('/')
                })
                .catch((e) => {
                    if (e.response) {
                        toast.error(e.response?.data.error)
                    }
                })
            }
        }else{
            toast.error('Você precisa atingir no mínimo R$50,00 para solicitar o reembolso!'+balance?.balance)
        }
    }

    useEffect(() => {
        async function loadAffiliate() {
            const response = await api.get('listAffiliate')
            setAffiliate(response.data)
            setLoading(false)
        }
        async function loadBalance() {
            const response = await api.get('listBalance')
            setListBalance(response.data)
            const response2 = await api.get('getBalance')
            setBalance(response2.data)
            setLoading(false)
        }
        loadAffiliate()
        loadBalance()

    }, []);

    return (
        <div id="page-room">
            <Header />
            <main className='content'>
                <div className='title-affiliate'>
                    <h1>Afiliado</h1>
                    <Button onClick={() => { createPayout() }}>Sacar</Button>
                </div>
                <div className={`${loading ? 'container-affiliate' : 'hide'}`}>
                    <div className='searching'>
                        <h1>Carregando...</h1>
                    </div>
                </div>
                <div className={`${loading ? 'hide' : 'container-affiliate'}`}>
                    <div className="lista-affiliate">
                        <div className='header-balance'>
                            {listBalance.map((balanceObj) => {
                                return (
                                    <p>{balanceObj.type}: {parseFloat(balanceObj.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                )
                            })}
                        </div>

                    </div>
                    <div className="lista-affiliate">
                        {affiliate.length > 0 ? (
                            affiliate.map((affiliate) => {
                                return (
                                    <div key={affiliate.idkey} className='affiliate-table'>
                                        <div className='affiliate-item'>
                                            <div className='flex-col'>
                                                <label className='label'>Data</label>
                                                <label className='label-valor label-date'>{affiliate.date}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Tipo</label>
                                                <label className='label-valor label-type'>{affiliate.type}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Nome</label>
                                                <label className='label-valor label-name'>{affiliate.name}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Valor</label>
                                                <label className='label-valor label-value'>{parseFloat(affiliate.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Comissão</label>
                                                <label className='label-valor label-value'>{parseFloat(affiliate.commission).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</label>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div className='no-data'>
                                <p>Você não indicou nenhum amigo. </p>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </div>
    )
}