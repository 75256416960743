
import '../styles/video-list.scss'
import { useNavigate } from 'react-router-dom'
import { Header } from '../componentes/Header'
import { useEffect, useState } from 'react'
import api from '../services/api'
import VideoType from '../types/video'
import SelectCategory from '../componentes/SelectCategory'
import { useUserAuth } from '../hooks/useUserAuth'
import locker from '../assets/img/locked.png'
import { toast } from 'react-toastify'



export function VideoList() {
    const history = useNavigate()
    const [videos, setVideos] = useState<VideoType[]>([])
    const [search, setSearch] = useState('')
    const [selectedValue, setSelectedValue] = useState('')
    const [loading, setLoading] = useState(true)
    var user = useUserAuth()

    async function go(id: string, type: string) {
        const response = await api.get(`subscription/active`)
        if (!response) {
            toast.error("Faça Login novamente")
        } else {
            if (!response.data.isActive && type !== "G") {
                toast.error("Assine para assistir")
                history('/subscription/new')
            } else {
                history('/video/' + id)
            }
        }

    }

    async function searching(find: string) {
        setSearch(find)
        const response = await api.post('video', new URLSearchParams({
            find: find,
            category: selectedValue
        }))
        setVideos(response.data)
    }

    async function handleSelectChange(value: string) {
        setLoading(true)
        const response = await api.post('video', new URLSearchParams({
            find: search,
            category: value
        }))
        setVideos(response.data)
        setSelectedValue(value)
        setLoading(false)
    }

    useEffect(() => {
        async function loadVideos() {
            const response = await api.post('video', new URLSearchParams({
                find: '',
                category: ''
            }))
            setVideos(response.data)
            setLoading(false)
        }
        loadVideos()
    }, [])

    return (
        <div id='page-room'>
            <Header />

            <main className='content-home'>
                <div className={`${loading ? 'container' : 'hide'}`}>
                    <div className='searching'>
                        <h1>Carregando...</h1>
                    </div>
                </div>
                <div className={`${loading ? 'hide' : 'container'}`}>
                    <div className='filter'>
                        <input
                            type='text'
                            placeholder='Buscar'
                            onChange={event => searching(event.target.value)}
                            value={search}
                        />
                        <SelectCategory onSelectChange={handleSelectChange} />
                    </div>
                    <div className='lista-videos'>
                        {videos.length > 0 ? (
                            videos.map((video) => {
                                return (
                                    <article className='video' key={video.id} onClick={() => { go(video.id, video.type) }}>
                                        <div className='thumb' style={{ backgroundImage: `url(${video.thumb})` }}>
                                            <h6 className='thumb-h6'>{video.title}</h6>
                                            {video.type === "G" &&
                                                <div className="tag"><div className="freeTag">Grátis</div></div>
                                            }
                                            {video.type !== "G" && (!user.user?.subscription) &&
                                                <div className="locker"><div className="freeLocker">
                                                    <h3>Conteúdo Exclusivo Assinantes</h3>
                                                    <img className="logo" src={locker} alt="Locker" />
                                                </div></div>
                                            }
                                        </div>
                                    </article>
                                );
                            })
                        ) : (
                            <div className='no-data'>
                                <p>Nenhum Vídeo foi encontrado</p>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </div>
    )
}