import { FormEvent, useState } from 'react'
import InputMask from 'react-input-mask'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'
import CCType from '../../types/cc'
import PaymentType from '../../types/payment'
import { toast } from 'react-toastify'
import { Button } from '../Button'

interface FormCC1Props {
    onChange: (newPayment: PaymentType) => void
    payment: PaymentType
}

export function FormCC(props: FormCC1Props) {
    const history = useNavigate()
    const [paymentState, setPaymentState] = useState<PaymentType>(props.payment)
    const [bloq, setBloq] = useState('button')
    const [descSave, setDescSave] = useState('Salvar')

    async function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target
        const updatedPayment = { ...paymentState }
        
        if (updatedPayment.creditCard) {
            updatedPayment.creditCard = {
                ...updatedPayment.creditCard,
                [name]: value,
            }
        }
        setPaymentState(updatedPayment)
    }

    async function handleCompra(event: FormEvent) {
        event.preventDefault()
        setBloq('button blocked')
        setDescSave('Enviando...')
        await api.post('cc', new URLSearchParams({...paymentState.creditCard, id: paymentState.priceid}))
            .then((response) => {
                if(response.data.status){
                    toast.success(response?.data.msg)
                    history('/')
                }else{
                    toast.error(response?.data.msg)
                    setBloq('button')
                    setDescSave('Salvar')
                }
            })
            .catch((e) => {
                if (e.response) {
                    toast.error(e.response?.data.error)
                    setBloq('button')
                    setDescSave('Salvar')
                }
            })
    }


    return (
        <form onSubmit={handleCompra}>
            <InputMask
                name="CardNumber"
                mask="9999 9999 9999 9999"
                value={paymentState.creditCard?.CardNumber}
                onChange={handleInputChange}
                placeholder='Cartão de Crédito'
                alwaysShowMask={false}

            />
            <InputMask
                name="CardExpiration"
                mask="99/9999"
                value={paymentState.creditCard?.CardExpiration}
                onChange={handleInputChange}
                placeholder='Mês Expiração'
                alwaysShowMask={false}
            />
            <InputMask
                name="CardSecurity"
                mask="999"
                value={paymentState.creditCard?.CardSecurity}
                onChange={handleInputChange}
                placeholder='Código Segurança'
                alwaysShowMask={false}
            />
            <input
                type='text'
                name='CardHolder'
                placeholder='Nome no Cartão'
                onChange={handleInputChange}
                value={paymentState.creditCard?.CardHolder}
            />
            <InputMask
                name="Zipcode"
                mask="99999-999"
                value={paymentState.creditCard?.Zipcode}
                onChange={handleInputChange}
                placeholder='CEP'
                alwaysShowMask={false}
            />
            <Button className={bloq}>{descSave}</Button>
        </form>
    )

}