import { FormEvent, useEffect, useState } from 'react'
import { Button } from '../Button'
import PaymentType from '../../types/payment'
import { toast } from 'react-toastify'
import api from '../../services/api'
import SelectPrice from '../SelectPrice'

interface FormMainProps {
    onChange: (newPayment: PaymentType) => void
    payment: PaymentType
}


export function FormMain(props: FormMainProps) {
    const [paymentState, setPaymentState] = useState<PaymentType>(props.payment)
    const [termsAccepted, setTermsAccepted] = useState(false)

    async function atualizaValor(event: string) {
        
        const updatedPayment = { ...paymentState }
        updatedPayment.priceid = event
        setPaymentState(updatedPayment)
    }

    async function handleChangePixCard(value: string) {
        const updatedPayment = { ...paymentState }
        if (updatedPayment.type) {
            updatedPayment.type = value
        }
        setPaymentState(updatedPayment)

    }

    async function submit(event: FormEvent) {
        event.preventDefault()
        if (termsAccepted) {
            props.onChange(paymentState)
        } else {
            toast.warning('Você precisa aceitar os termos de uso e políticas de privacidade.')
        }

    }

    async function handleTermsAcceptance() {
        setTermsAccepted(!termsAccepted)
    }

   

    return (
        <form onSubmit={submit}>
            <select className='filter-select' onChange={event => handleChangePixCard(event.target.value)} value={paymentState.type} >
                <option value="PIX">PIX</option>
                <option value="Cartão de Crédito">Cartão de Crédito</option>
            </select>
            <input
                readOnly
                type='text'
                placeholder='Nome'
                value={paymentState.user?.name}
            />
            <SelectPrice product_id='2' onSelectChange={atualizaValor}/>

            
            <div>
                <input
                    className='checkbox'
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={handleTermsAcceptance}
                />
                <label>Declaro que eu aceito os Termos de Uso e as Políticas de Privacidade</label>
            </div>

            <Button>Avançar</Button>
        </form>

    )

}